<template>
    <consultoria-form
        operacao="ATUALIZAR"
        @salvar="atualizar($event)"
        @obterConsultoriaAtualizada="obterConsultoria()"
        :consultoria="consultoria"
        :cancelar="cancelar"
        :erros="erros"
        :services="obterServices()"
    ></consultoria-form>
</template>

<script>
import Services from './services';
import ConsultoriaForm from './ConsultoriaForm';

export default {
    components: {
        ConsultoriaForm,
    },

    data() {
        return {
            consultoria: null,
            erros: [],
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        cancelar() {
            this.$router.push({ name: 'Utilidades_Consultorias' });
        },

        obterConsultoria() {
            this.$store.dispatch('addRequest');
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.consultoria = response.data;
                } else {
                    this.consultoria = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(consultoriaAtualizada) {
            this.$store.dispatch('addRequest');
            consultoriaAtualizada.consultoriaId = this.consultoria.consultoriaId;
            Services.atualizar(consultoriaAtualizada).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Utilidades',
                        detail: 'Consultoria atualizar com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar() {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Utilidades_Consultorias_Detalhar',
                params: {
                    id: this.consultoria.consultoriaId,
                },
            });
        },
    },

    created() {
        this.obterConsultoria();
    },
};
</script>
